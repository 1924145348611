import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import useBreakpoint from '~utils/useBreakpoint'
import ScrollSelectorMobile from '~components/ScrollSelectorMobile'
import ScrollSelector from '~components/ScrollSelector'
import { useSiteState } from '~context/siteContext'
import Seo from '~components/Seo'

const Post = ({ data }) => {
	const page = {...data?.sanityPost, ...data?.allSanityProject}
	const { isMobile, loaded } = useBreakpoint()
	const [siteState, setSiteState] = useSiteState()

	useEffect(() => {
		setSiteState(prevState => ({
			...prevState,
			pageTitle: page.title,
		}))
	}, [])


	return (
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			{loaded &&
			<Wrap>
				{isMobile ? 
					<ScrollSelectorMobile data={page} bgColor='black'/>
					:
					<ScrollSelector data={page} bgColor='black'/>
				}
				<DataViewer data={page} name="page"/>
			</Wrap>
			}
		</>
	)
}

const Wrap = styled.div`
`

Post.propTypes = {
	data: PropTypes.object
}

export const query = graphql`
	  query PostQuery($id: String) {
    	sanityPost(id: {eq: $id}) {
				title
				slug {
					current
				}
				seo{
					...seo
				}
				projects {
					thumbnail {
						...thumbnail
					}
					title
					_type
					client
					projectName
					slug {
						current
					}
				}
			}
		}
`

export default Post